import {
    QuestionAnswer,
    InsertEmoticon,
    Quiz,
    Poll,
    TextFields,
    Cloud,
    Flaky,
    TouchApp,
    Photo, QuestionMark, Mic, PanTool
} from "@mui/icons-material";

const questionType = {
    qna: {
        text: 'Q&A',
        icon: <QuestionAnswer/>
    },
    toolpress: {
        text: 'Toolpress',
        icon: <QuestionAnswer/>
    },
    quiz: {
        text: 'Quiz',
        icon: <Quiz/>
    },
    poll: {
        text: 'Sondaggio',
        icon: <Poll/>
    },
    wordcloud: {
        text: 'Wordcloud',
        icon: <Cloud/>
    },
    sentiment: {
        text: 'Sentiment',
        icon: <InsertEmoticon/>
    },
    siNo: {
        text: 'Sí/No',
        icon: <Flaky/>
    },
    clickTo:{
        text: 'Click To',
        icon: <TouchApp/>
    },
    sendPhoto:{
        text:"Invio foto",
        icon:<Photo/>
    },
    audio:{
        text:"Invio audio",
        icon:<Mic/>
    },
    reserveSpot: {
        text: "Prenota il tuo posto!",
        icon: <PanTool/>
    },
    altro:{
        text:"Altro",
        icon:<QuestionMark/>
    },
}

export default questionType